import type { ItemReference, PressReleases } from "~/types/drupal_jsonapi";
import CommonFirstScreenImage from "~/components/shared/CommonFirstScreenImage";
import FiltersBox from "~/components/PressReleases/Components/FiltersBox";
import { createStore } from "solid-js/store";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { useSearchParams } from "@solidjs/router";
import { urlRs } from "~/utils/url";
import { createRenderEffect, For, Show } from "solid-js";
import PressReleaseCard from "~/components/PressReleases/PressReleaseCard";
import { Pagination, paginationSlices } from "~/components/shared/Pagination";
import ContactPartMain from "~/components/PressReleases/Components/ContactPartMain";

import type { ItemReferencePaginationStore } from "~/components/shared/Pagination";

import "~/components/shared/CommonPageStyle.css";
import "./PressReleasesPage.css";
import GtmVars from "../shared/Trackers/GtmVars";

const [store, setStore] = createStore<ItemReferencePaginationStore>({
  slices: [],
  currentPage: 0,
  displayedItems: [],
});

export default function PressReleasesPage(props: {
  pressReleases: PressReleases;
}) {
  const settings = useDrupalSettingsContext();
  const [searchParams] = useSearchParams();

  const path = () => {
    return urlRs("press_releases", "/espace-presse/", settings);
  };

  createRenderEffect(() => {
    setStore("currentPage", parseInt(searchParams.page || "0"));
  });

  createRenderEffect(() => {
    const slices = paginationSlices(
      searchParams.year
        ? props.pressReleases.items.filter(
            (item) => item.year.toString() == searchParams.year,
          )
        : props.pressReleases.items,
    );
    setStore("slices", slices as unknown as ItemReference[][]);
  });

  createRenderEffect(() => {
    setStore("displayedItems", store.slices[store.currentPage].filter(Boolean));
  });

  const gtmValues = () => {
    return {
      "all.pageType": "other",
      "all.mainCategory": "Espace presse",
      "all.category": "Non défini",
      "all.subCategory": "Non défini",
      "all.subsubCategory": "Non défini",
      "all.subsubsubCategory": "Non défini",
      template: "press",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="common-page-style press-releases">
        <CommonFirstScreenImage
          image="/images/press_releases-bg.jpg"
          alt="Espace presse"
        />

        <div class="outer-content-area">
          <div class="content-area" data-ga-zone="in-page">
            <div class="inner">
              <div data-test="introduction">
                <h1>Espace presse</h1>
                <h2 class="page-catchline" data-test="catchline">
                  Retrouvez l’ensemble des communiqués et dossiers de presse
                  publiés par Cogedim.
                </h2>
              </div>

              <FiltersBox items={props.pressReleases.items} />

              <div class="list-press-releases" data-test="releases">
                <For each={store.displayedItems}>
                  {(pressRelease) => (
                    <PressReleaseCard
                      nid={pressRelease.id}
                      item={pressRelease}
                    />
                  )}
                </For>
              </div>

              <Show when={store.slices.length > 1}>
                <Pagination
                  currentPage={store.currentPage}
                  totalPages={store.slices.length}
                  url={
                    searchParams.year
                      ? path() + `?year=${searchParams.year}`
                      : path()
                  }
                  type="press_releases"
                />
              </Show>
            </div>
          </div>

          <ContactPartMain
            contacts_main_altarea={props.pressReleases.contacts_main_altarea}
            contacts_main_agence_shan={
              props.pressReleases.contacts_main_agence_shan
            }
          />
        </div>
      </article>
    </>
  );
}
